import {backend, user} from './services/backend';

import { inject } from 'aurelia-framework';
import { Router, activationStrategy } from 'aurelia-router';
//import * as $ from 'jquery';

@inject(Router)
export class Terms {
  request = {};

  constructor(router) {
    this.router = router;
    this.user = user;
    this.parent = router.container.viewModel;
    console.log('Terms parent:', this.parent);
  }

  activate(params) {
      //this.parent.dark = false;
  }

  bind(bindingContext, overrideContext) {
  }

  attached() {

  }

}
