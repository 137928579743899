/* eslint-disable curly */
import {backend, user} from './services/backend';
import { inject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Languages, Countries } from './services/languages';

@inject(Router)
export class ProfileFrame {
	@bindable showPassword;
	languages = Languages;
	countries = Countries;

	constructor(router) {
		this.router = router;
		this.user = user;
		this.parent = router.container.viewModel;
		this.JSON = JSON;
		this.Math = Math;
	}

	attached() {
		if (!user.update.data)
			user.update.data = {langs: []};
		if (!user.update.data.langs)
			user.update.data.langs = [];
		this.params = this.router.currentInstruction.params;
		$('[data-toggle="popover"]').popover();
		this.error = '';
	}

	activate(params) {
	    //this.page = parseInt(params.page || '1');
	    console.log('ProfileFrame.activate:', params);
	    this.error = '';
	}

	avatarChange() {
		console.log('Avatar changed:', this.avatar);
		this.avatarPreview = null;
		if (!this.avatar.length) return;
		let reader = new FileReader();

		reader.onload = (e) => {
			//$('#blah').attr('src', e.target.result);
			this.avatarPreview = reader.result;
			console.log('Image url loaded:', e);
		};

		reader.readAsDataURL(this.avatar[0]);
	}

	async saveAvatar() {
		if (!this.avatar || !this.avatar.length) return;

		let formData = new FormData();
		formData.append('image', this.avatar[0]);

		return $.post({
			//type: "POST",
			url: '/upload/avatar/',
			data: formData,
			processData: false,
			contentType: false,
			error: (e) => {
				console.log('some error', e);
				this.rror = e.message;
			},
		}).then((r) => {
			this.avatarPreview = null;
			this.user.update.data.avatar = r;
		});
	}

	async save() {
		if (this.user.update.password !== this.user.update.confirm) {
			this.error = 'Confirmation password does not match';
			return;
		}
		let save;
		this.error = '';
		if (user.anonymous)
			try {
				//user.editor.email = user.update.email;
				user.editor.password = user.update.password;
				user.editor.confirm = user.update.confirm;
				user.editor.phone = user.update.phone;
				save = JSON.parse(JSON.stringify(user.update));
				//console.log('Before new userrr:', user, save);
				await user.register();
				//user.update = save;
				//console.log('New userrr:', user);
			} catch (e) {
				this.error = e.message;
				return;
			}
		else save = user.update;
		await this.saveAvatar();
		return await backend
			.service('users')
			.patch(this.user.id, save)
			.then((u) => {
				this.user.setUserData(u);
				console.log('Updated:', save, u);
				if (this.params.r) return this.router.navigate(this.params.r);
				this.parent.alert('Profile saved', 'success', true, 4000);
				return true;
			})
			.catch((e) => {
				this.error = e.message;
			});
	}
}
