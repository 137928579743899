import moment from 'moment';
import {valueConverter} from 'aurelia-binding';

@valueConverter('d')
export class DValueConverter {
	toView(value, format) {
	    let result = moment(value).format(format || 'll');
	    //console.log('To date:', value, result);
	    if (!value)
	        return '';
	    return result;
	}
	fromView(value, format) {
	    let result = moment(value, format).toISOString();
	    //console.log('From date:', value, result);
	    if (!value)
	        return null;
	    return result;//toDate();
	}
}


