import {inject} from 'aurelia-framework';
import { BindingSignaler, SignalBindingBehavior } from 'aurelia-templating-resources';
import {I18N, TBindingBehavior} from 'aurelia-i18n';
import {backend} from '../../services/backend';
import {bindingBehavior} from 'aurelia-binding';

@bindingBehavior('tt')
@inject(I18N, backend, SignalBindingBehavior)
class TtBindingBehavior {
	constructor(i18n, backend, signalBindingBehavior) {
		this.i18n = i18n;
		this.backend = backend;
		this.signalBindingBehavior = signalBindingBehavior;
	}
	bind(binding, scope, options) {
		this.updateTarget = binding.updateTarget;//.bind(binding);
		this.updateSource = binding.updateSource;//.bind(binding);

		this.signalBindingBehavior.bind(binding, scope, 'aurelia-translation-signal');

		if (binding.prepared) {return;}


		binding.updateTarget = (value) => {
            if (binding.targetObserver) {
                //console.log("Storing observer:", binding)
                this.targetObserver = binding.targetObserver;
            }
            //console.log('TT:', value, options);
			let result;
			let from = options && options.from ? options.from : 'en';
			let to = this.i18n ? this.i18n.getLocale() : 'en';

			if (from == to) {
			    result = value;
			} else {
			    if (this.i18n)
			        result = this.i18n.tr(value, options)
                else
                    result = '';
			}

			if (result === false) {
			    this.backend.service('translator')._get(value, {query: {from, to}}).then((result) => {
                    if(!binding.targetObserver){
                        //console.log("No target observer :(");
                        binding.targetObserver = this.targetObserver;
                    }
                    //console.log('updateTarget on:', binding);
					this.updateTarget.call(binding, result.translation);
				});
			} else {
                //console.log('updateTarget2 on:', binding);
			    this.updateTarget.call(binding, result);
			}
		};
		binding.prepared = true;
	}

	unbind(binding, scope) {
		binding.updateTarget = this.updateTarget;
		binding.updateSource = this.updateSource;

		this.signalBindingBehavior.unbind(binding, scope);
	}
}

let tb = new TtBindingBehavior;

//TBindingBehavior.prototype = TtBindingBehavior.prototype;
console.log('TBindingBehavior:', TBindingBehavior.prototype);
//let old = TBindingBehavior.prototype.bind;

//TBindingBehavior.prototype.i18n = tb.i18n;
//TBindingBehavior.prototype.backend = backend;
let globalI18n;

TBindingBehavior.prototype.bind = function(...args) {
	//console.log('TBindingBehavior.prototype.bind', {this: this, args});
	if (!this.i18n){
	    this.i18n = args[1].bindingContext.i18n ? args[1].bindingContext.i18n : args[1].overrideContext.parentOverrideContext ? args[1].overrideContext.parentOverrideContext.i18n : globalI18n;
	    this.backend = backend;
    }

	if (this.i18n) {
        globalI18n = this.i18n;
    } //else
      //  old.apply(this, args);
    TtBindingBehavior.prototype.bind.apply(this, args);
};

TBindingBehavior.prototype.unbind = TtBindingBehavior.prototype.unbind;
/**/

//let TtBindingBehavior = TBindingBehavior;

export { TtBindingBehavior };


