if (Number.EPSILON === undefined) {
	Number.EPSILON = Math.pow(2, -52);
}

let DecimalPrecision = {
	round: function(n, p=2){
		let r = 0.5 * Number.EPSILON * n;
		let o = 1; while(p-- > 0) o *= 10;
		if(n < 0)
			o *= -1;
		return Math.round((n + r) * o) / o;
	},
	ceil: function(n, p=2){
		let r = 0.5 * Number.EPSILON * n;
		let o = 1; while(p-- > 0) o *= 10;
		if(n < 0)
			o *= -1;
		return Math.ceil((n + r) * o) / o;
	},
	floor: function(n, p=2){
		let r = 0.5 * Number.EPSILON * n;
		let o = 1; while(p-- > 0) o *= 10;
		if(n < 0)
			o *= -1;
		return Math.floor((n + r) * o) / o;
	}
};

export { DecimalPrecision };
