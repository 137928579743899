let scriptElement = null, loaderScriptElement;

let key = 'AIzaSyCS41qOTAO8tQZW1cONf9DD8zrlR1jrybU';
export const googleApiKey = key;
export function initPlaceApi(done) {
	if (scriptElement)
		return done();

	let loaderURL = 'https://apis.google.com/js/api.js';
	let scriptURL = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&libraries=geometry,places&language=en';

	scriptElement = document.createElement('script');
	scriptElement.src = scriptURL;
	scriptElement.onload = () => {
		loaderScriptElement = document.createElement('script');
		loaderScriptElement.src = loaderURL;
		loaderScriptElement.onload = () => {
			gapi.load('client', done)
			//done();
		};
		let scriptElementInHead2 = document.querySelector('head').appendChild(loaderScriptElement);
	};

	let scriptElementInHead = document.querySelector('head').appendChild(scriptElement);
}

const setAPILanguage = (lang, libraries) => {
	//Destroy old API
	document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
		script.remove();
	});
	if(google) delete google.maps;

	//Generate new Google Maps API script
	let newAPI = document.createElement('script');
	newAPI.src = 'https://maps.googleapis.com/maps/api/js?libraries=' + libraries.join(',') + '&key=' + key + '&language=' + lang + '&callback=googleMapsAPILoaded';

	//Callback for the Google Maps API src
	window.googleMapsAPILoaded = () => {
		let event = new CustomEvent('googleMapsAPILoaded');
		window.dispatchEvent(event);
	}

	//Wait for the callback to be executed
	let apiLoaded = new Promise(resolve => {
		window.addEventListener('googleMapsAPILoaded', () => {
			resolve();
		});
	});

	//Start the script
	document.querySelector('head').appendChild(newAPI);

	return apiLoaded;
}
