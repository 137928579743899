/* eslint-disable curly */
import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import { CookieStorage } from 'cookie-storage';
import _ from 'lodash/core.min.js';

let baseUrl = '/';//'https://panapartment.com/'; 
//let baseUrl = 'https://pan-ap.com/';
// let baseUrl = 'localhost';
let socket = io.connect(baseUrl);
//let socket = io(window.location.origin, {
//let socket = io('/', {
// path: baseUrl
//});
console.log('Socket:', socket);

let backend = feathers
	.default()
	//.configure(feathers.hooks())
	.configure(feathers.socketio(socket, {
		timeout: 1000 * 60 * 2 // 2 min
	}))
	.configure(
		feathers.authentication({
			storage: new CookieStorage({ path: '/' }), //window.localStorage
		})
	);

socket.on('reconnecting', function (delay, attempt) {
	console.log('reconnecting');
});

socket.on('reconnect', function () {
	console.log('reconnected');
});

socket.on('reconnect_failed', function () {
	console.log('reconnect failed');
	setTimeout(() => {
		socket.socket.reconnect();
	}, 10000);
});

backend.__internalService = backend.service;
backend.service = function (path, service) {
	if (service) {
		return backend.__internalService.call(backend, path, service);
	}

	service = backend.__internalService.call(backend, path);
	if (!service) {
		service = backend.__internalService.call(backend, path);
	}
	if (service._find) {
		return service;
	}

	service._find = service.find;
	service.find = function (...params) {
		return queryBackend(path, '_find', ...params);
	};
	service._get = service.get;
	service.get = function (...params) {
		return queryBackend(path, '_get', ...params);
	};
	return service;
};

const dataStore = {};

function hashQuery(p) {
	let params = { ...p };
	if (params.$skip) delete params.$skip;
	let keys = Object.keys(params).sort();
	let result = [];

	for (let i in keys) {
		let prop = keys[i];
		let val = params[prop];
		if (val && typeof val === 'object') {
			if (val.$contains) {
				if (Array.isArray(val.$contains)) {
					val = val.$contains[0];
				} else {
					val = val.$contains;
				}
			} else if (val.$overlap) {
				if (Array.isArray(val.$overlap)) {
					val = val.$overlap.join(',');
				} else {
					val = val.$overlap;
				}
			} else {
				val = JSON.stringify(val);
			}
		}
		result.push(prop + '/' + val);
	}
	return {
		keys: keys,
		hash: result.join('/'),
	};
}

function hashObject(obj, keys, i, hash, done) {
	function complete(hash) {
		if (i < keys.length - 1) {
			hashObject(obj, keys, i + 1, hash + '/', done);
		} else {
			done(hash);
		}
	}

	let val = obj[keys[i]];
	if (Array.isArray(val)) {
		for (let j in val) {
			complete(hash + keys[i] + '/' + val[j]);
		}
	} else {
		complete(hash + keys[i] + '/' + val);
	}
}


function isObject(val) {
	if (val === null) { return false;}
	return ( /*(typeof val === 'function') ||*/ (typeof val === 'object') );
}

function findQuery(queries, query) {
	let result;
	_.forEach(queries, (obj) => {
		if (_.isEqual(obj.query, query)){
			result = obj;
			// break forEach
			return false;
		}
	});
	return result;
}

function checkObject(query, obj) {
	let result = true;
	_.forEach(query, (val, key) => {
		if (isObject(val)) {
			if (val.$in)
				result = result && val.$in.indexOf(obj[key]) >= 0;
		} else {
			result = result && obj[key] === val;
		}
		if (!result)
			return false;
	});
	return result;
}

function queryBackend(service, method, ...params) {
	return new Promise((resolve, reject) => {
		function clearObj(obj) {
			let keys = Object.keys(obj);

			// Delete object from arrays
			/*for (let key in store.keys) {
				let keys = store.keys[key];

				hashObject(obj, keys, 0, '', (query) => {
					if (store.queries[query]) {
						let i = store.queries[query].indexOf(store.objects[obj.id]);
						if (i >= 0) {
							store.queries[query].splice(i, 1);
						}
					}
				});
			}*/
			_.forEach(store.queries, (query) => {
				let i = query.result.indexOf(store.objects[obj.id]);
				if (i >= 0) {
					query.result.splice(i, 1);
				}
			});

			// Delete object keys
			for (let i in keys) {
				let prop = keys[i];
				delete obj[prop];
			}
		}

		function onChange(data) {
			function update(obj) {
				if (!store.objects[obj.id]) {
					store.objects[obj.id] = obj;
					// New object
					// Check for compatible queries
					_.forEach(store.queries, (query, index) => {
						if (checkObject(query.query, obj))
							query.result.push(obj);
					});
				} else {
					Object.assign(store.objects[obj.id], obj);
				}

				/*for (let key in store.keys) {
					let keys = store.keys[key];

					hashObject(obj, keys, 0, '', (query) => {
						console.log('Object hash:', query, obj);
						if (
							store.queries[query] &&
							store.queries[query].indexOf(store.objects[obj.id]) < 0
						) {
							store.queries[query].push(store.objects[obj.id]);
						}
					});
				}*/
			}

			console.log('OnChange Event:', data);

			if (Array.isArray(data)) {
				for (let i in data) {
					let obj = data[i];
					update(obj);
				}
			} else if (data.id) {
				update(data);
			}
		}

		const srv = backend.service(service);

		let store = dataStore[service];
		if (!store) {
			store = {
				objects: {}, // individual objects
				queries: [], // queries and results
				keys: {},
			};

			srv.on('created', onChange);
			srv.on('updated', onChange);
			srv.on('patched', onChange);
			srv.on('removed', (data) => {
				if (data.id && store.objects[data.id]) {
					let obj = store.objects[data.id];
					clearObj(obj);
				}
			});

			dataStore[service] = store;
		}

		if (method == '_get' && store.objects[params[0]]) {
			return resolve(store.objects[params[0]]);
		}

		if (method == '_find') {
			/*var hash = hashQuery(params[0].query);
			console.log('Hash:', hash);

			let result = store.queries[hash.hash];
			if (result) {
				return resolve(result);
			}*/
			// Find previous query with the same params
			let query = {...params[0].query};
			if (query.$skip)
				delete query.$skip;
			let res = findQuery(store.queries, query);
			if (res)
				return resolve(res.result);
		}

		srv[method](...params)
			.then((data) => {
				console.log(
					`Results for ${method} ${service} where ${JSON.stringify(
						params,
						null,
						'\t'
					)}:`,
					data
				);
				let result;

				if (!data.id && data.data) {
					// Probably array
					if (data.data && (data.data.length > 0 || data.data.length === 0)) {
						result = data.data;
						checkNext(data);
					} else {
						return reject(new Error('Unknown results'));
					}
				} else {
					result = data;
				}

				// Store results
				if (method == '_get') {
					store.objects[result.id] = result;
				}
				if (method == '_find') {
					//store.keys[hash.keys.join('/')] = hash.keys;
					let query = {...params[0].query};
					if (query.$skip)
						delete query.$skip;
					// Store result array
					store.queries.push({query, result});

					for (let i in result) {
						let obj = result[i];
						let stored = store.objects[obj.id];
						if (stored) {
							result[i] = stored;
						} else {
							store.objects[obj.id] = obj;
						}
					}
				}
				resolve(result);

				function checkNext(data) {
					if (data.data.length + (data.skip || 0) < data.total) {
						// Load next
						params[0].query.$skip = data.data.length + (data.skip || 0);
						console.log('Before next:', service, params);
						srv[method](...params).then((data) => {
							console.log('Next pages loaded:', service, params, data);
							checkNext(data);
							for (let i in data.data) {
								let obj = data.data[i];
								let stored = store.objects[obj.id];
								if (stored) {
									obj = stored;
								} // else {store.objects[obj.id] = obj;}
								result.push(obj);
							}
						});
					}
				}
			})
			.catch((e) => reject(e));
	});
}

class User {
	editor = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		confirm: '',
		phone: '',
	};
	update = {};
	apartments = null;
	requests = null;

	constructor() {
		this.setUserData({});
	}
	setUserData(user) {
		this.editor.message = '';
		this.update = {
			firstName: user.firstName,
			lastName: user.lastName,
			phone: user.phone,
			birthday: user.birthday,
			residence: user.residence,
			nationality: user.nationality,
			data: { ...user.data },
		};

		this.firstName = user.firstName;
		this.lastName = user.lastName;
		this.email = user.email;
		this.phone = user.phone;
		this.id = user.id;
		this.data = user.data;
		this.nationality = user.nationality;
		this.residence = user.residence;
		this.payout = user.payout;
		this.anonymous = this.id ? false : true;
		this.emailConfirmed = user.emailConfirmed;
        this.phoneConfirmed = user.phoneConfirmed;
		this.payoutConfirmed = user.payoutConfirmed;
		this.role = user.role;

		this.userData = user;

		if (this.anonymous) {
			this.apartments = null;
			this.requests = null;
		} else {
			this.loadData();
		}
	}
	loadData() {
		// Get apartments and active requests
		backend
			.service('apartments')
			.find({ query: { userId: this.id } })
			.then((data) => {
				console.log('Apartments:', data);
				this.apartments = data;
			})
			.catch((e) => console.error(e));
		backend
			.service('requests')
			.find({ query: { userId: this.id } })
			.then((data) => {
				console.log('Requests:', data);
				this.requests = data;
			})
			.catch((e) => console.error(e));
	}
	login(options) {
		return new Promise((resolve, reject) => {
			backend
				.authenticate(options)
				.then((authResponse) => {
					console.log('user:', authResponse);
					//this.user = authResponse.user;
					this.setUserData(authResponse.user);
					this.initialized = true;
					resolve(user);
					backend.emit('user', this);
				})
				.catch((e) => {
					this.setUserData({});
					this.initialized = true;
					// Show login page (potentially with `e.message`)
					console.error('Authentication error', e);
					reject(e);
					//$('#error').text(e.message).show();
				});
		});
	}
	async sendLoginLink() {
		this.editor.message = '';
		return backend
			.service('users')
			.patch('login', { email: this.editor.email })
			.then((result) => {
				this.editor.error = '';
				this.editor.message = 'Login link was sent to ' + this.editor.email;
				console.log('Login link sent:', result, this);
			})
			.catch((e) => {
				this.editor.error = e.message;
			});
	}
	async resendConfirmationEmail() {
		if (!this.id) return;

		return backend.service('users').patch(this.id, { confirmationEmailSent: null });
	}
	logout() {
		backend.logout();
		this.setUserData({});
		//backend.emit('logout', this);
	}
	async register() {
		if (this.editor.password !== this.editor.confirm)
			throw new Error('Passwords does not match!');
		if (!this.editor.password) throw new Error('Passwords can`t be blank');
		if (!this.editor.email) throw new Error('Email can`t be blank');
		if (!this.editor.phone) throw new Error('Phone can`t be blank');

		const newUser = {
			firstName: this.editor.firstName,
			lastName: this.editor.lastName,
			email: this.editor.email,
			phone: this.editor.phone,
			password: this.editor.password,
		};
		return backend
			.service('users')
			.create(newUser)
			.then((data) => {
				console.log('New User:', data);
				gtag('event', 'conversion', {'send_to': 'AW-11030423389/6HQdCM7_jYMYEN3O24sp'});
				return this.login({
					strategy: 'local',
					email: newUser.email,
					password: newUser.password,
				});
			});
	}
}
const user = new User();

function init(done) {
	//if (user.initialized) {return done(user);}

	const finish = async function (data) {
		console.log('Login data', data);
		user.setUserData(await backend.service('users').get(data.user.id));
		user.initialized = true;
		done(user);
		backend.emit('user', user);
	};
	backend
		.authenticate()
		.then(finish)
		.catch(() => {
			user.setUserData({});
			user.initialized = true;
			done(user);
		});

	/*backend.authentication.getFromLocation(location)
      .then((data) => {
      });*/
}

export { backend, user, init, dataStore, checkObject };

var isEqual = (first, second) => {
	if (first === second) {
		return true;
	}
	if ((first === undefined || second === undefined || first === null || second === null)
		&& (first || second)) {
		return false;
	}
	const firstType = first.constructor.name;
	const secondType = second.constructor.name;
	if (firstType !== secondType) {
		return false;
	}
	if (firstType === 'Array') {
		if (first.length !== second.length) {
			return false;
		}
		let equal = true;
		for (let i = 0; i < first.length; i++) {
			if (!isEqual(first[i], second[i])) {
				equal = false;
				break;
			}
		}
		return equal;
	}
	if (firstType === 'Object') {
		let equal = true;
		const fKeys = Object.keys(first);
		const sKeys = Object.keys(second);
		if (fKeys.length !== sKeys.length) {
			return false;
		}
		for (let i = 0; i < fKeys.length; i++) {
			if (first[fKeys[i]] && second[fKeys[i]]) {
				if (first[fKeys[i]] === second[fKeys[i]]) {
					continue; // eslint-disable-line
				}
				if (first[fKeys[i]] && (first[fKeys[i]].constructor.name === 'Array'
					|| first[fKeys[i]].constructor.name === 'Object')) {
					equal = isEqual(first[fKeys[i]], second[fKeys[i]]);
					if (!equal) {
						break;
					}
				} else if (first[fKeys[i]] !== second[fKeys[i]]) {
					equal = false;
					break;
				}
			} else if ((first[fKeys[i]] && !second[fKeys[i]]) || (!first[fKeys[i]] && second[fKeys[i]])) {
				equal = false;
				break;
			}
		}
		return equal;
	}
	return first === second;
};
