/* eslint-disable curly */
import { inject, bindable, bindingMode, children, processContent } from 'aurelia-framework';
import {DOM} from 'aurelia-pal';

let counter = 0;

class PanOption {
	constructor(id, title, select) {
	    this.id = id;
	    this.title = title;
	    this.select = select;
	}

	get css() {
	    const select = this.select;
	    const value = select.value;
	    return value && (this.id == value || (select.multiple && value.indexOf(this.id) >= 0)) ? 'active' : '';
	}
}

/*@processContent((compiler, resources, node, instruction) => {
	console.log({compiler, resources, node, instruction});
	instruction.type.target.inlineOptions = Array.from(node.querySelectorAll('option'));
	return true;
})*/
//@processContent(false)
@inject(Element)
export class PanSelect {
    @children('option') inlineOptions;
    @bindable options;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable canCreate;
    @bindable multiple;
    @bindable placeholder;
    @bindable required;
    @bindable allowClear;

    optionsLookup = {};
    optionsArray = [];
    searchOptionsArray = [];
    index = -1;

    found;
    focused;
    searching;
    skipEvents;

    constructor(element) {
    	this.element = element;
    	this.id = counter;
    	counter++;
    }

    createOptions() {
    	let optionsArray = [];
    	this.optionsLookup = {};
    	const options = this.options;

    	// Create inline options
    	if (this.inlineOptions && this.inlineOptions.length)
    		for (let option of this.inlineOptions) {
    			const text = option.innerText;
    			const id = option.value || text;
    			optionsArray.push(new PanOption(option.value || text, text, this));
    			this.optionsLookup[id] = text;
    		}

    	// Create options from attribute
    	if (options instanceof Array)
    		for (let key of options) {
    			if (key != '__observers__') {
    				optionsArray.push(new PanOption(key, key, this));
    				this.optionsLookup[key] = key;
    			}
    		}
    	else {
    		for (let key in options) {
    			if (key != '__observers__') {
    			    optionsArray.push(new PanOption(key, options[key], this));
    				this.optionsLookup[key] = options[key];
    			}
    		}
    	}

    	this.optionsArray = optionsArray;
    	if (this.searching)
    		this.performSearch();
    	else
    		this.valueChanged(this.value);
    }

    optionsChanged(options) {
		this.createOptions();
    }

    inlineOptionsChanged(inlineOptions) {
		this.createOptions();
    }

    valueChanged(val) {
    	if (!this.multiple)
    	    this.search = this.optionsLookup[val] || val;
    	this.searching = false;
    }

    changed() {
    	let event = DOM.createCustomEvent('change', { bubbles: true });
    	event.srcComponent = this;
		setTimeout(() => this.element.dispatchEvent(event), 0);
    }

    selectOption(option) {
    	this.searching = false;

    	if (!this.multiple) {
    	    if (option.id) {
    	        this.value = option.id;
    	        //this.search = option.title;
    	    } else
    	        this.value = option;

    	    this.changed();
    	    return;
    	}

    	if (!this.value)
    	    this.value = [];

    	if (this.search == option) {
    	    let index = this.value.indexOf(option);
    	    if (index == -1)
    	        this.value.push(option);
    	    else
    	        this.value.splice(index, 1);
    	} else {
    	    let index = this.value.indexOf(option.id);
    	    if (index == -1)
    	        this.value.push(option.id);
    	    else
    	        this.value.splice(index, 1);
    	}
    	this.search = '';
    	this.changed();
    }

    isActive(option, value) {
    	if (value && (option == value || option.id == value ||
            (this.multiple && value.indexOf(option.id) >= 0)))
    	    return 'active';
    	return '';
    }

    deleteItem(id) {
    	this.checkedColor = false;
    	let index = this.value.indexOf(id);

    	if (index > -1) {
    		this.value.splice(index, 1);
    	}
    }


    performSearch() {
    	this.found = false;
    	let search = this.search.toUpperCase();

    	for (let option of this.optionsArray) {
    		let i = option.title.toUpperCase().indexOf(search);
    		if (i >= 0) {
    			option.before = option.title.substring(0, i);
    			option.search = option.title.substring(i, i + search.length);
    			option.after = option.title.substring(i + search.length);
    		} else
    			option.search = '';

    		if (search == option.title.toUpperCase())
    			this.found = true;
    	}

    	this.searching = (search != '');
    }

    dropdown(event) {
    	//event.stopPropagation();
    	setTimeout(() => {
    	    $(this.button || this.input).dropdown('show');
    	    console.log('Htmling:', this.button || this.input);
    	}, 0);
    	this.input.focus();
    }

    focusout() {
    	setTimeout( () => {
    	    // Check if our custom element is not focused
    		if (this.searching && !this.element.contains(DOM.activeElement)) {
    		    console.log('Focus out');
    		    if (this.canCreate) {
    		        this.value = this.search;
    		        this.changed();
    		    } else {
    		        // Clear search
    		        this.valueChanged(this.value);
    		    }
    		}
    	}, 0);
    	//(!this.element.contains(document.activeElement)
    }
}
