/* eslint-disable curly */
import {backend, user} from './services/backend';

import { inject } from 'aurelia-framework';
import { Router, activationStrategy } from 'aurelia-router';
//import * as $ from 'jquery';
//import 'select2/dist/css/select2.min.css';
//import 'select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';


@inject(Router)
export class Profile {
  request = {};

  constructor(router) {
  	this.router = router;
  	this.user = user;
  	this.parent = router.container.viewModel;
  	this.JSON = JSON;
  	this.Math = Math;
  }

  activate(params) {
  	//this.page = parseInt(params.page || '1');
  	console.log('profile.activate:', params);
  	this.params = params;
  	this.error = '';
  }

  delete(apartment) {
  	if (confirm('Are you sure?')) {
  		backend
  			.service('apartments')
  			.remove(apartment.id);
  	}
  }

  // This is necessary to tell Aurelia router not to reuse
  // the same view model whenever navigating between pages
  // so that the activate method gets called each time
  determineActivationStrategy() {
  	return activationStrategy.replace;
  }
}
