import {valueConverter} from 'aurelia-binding';

@valueConverter('keys')
export class KeysValueConverter {
	toView(obj) {
		if (!obj) {
			return [];
		}
		let result = Reflect.ownKeys(obj).filter(x => x !== '__observers__');
		//console.log("Keys Value Converter:", result);
		return result;
	}
}
