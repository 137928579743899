export const Classes = {
    1: 'Hostel',
    2: 'Standard',
    4: 'Business',
    8: 'Lux Apartment',
    16: 'House / Villa',
    32: 'Manor, Castle, Private island'
};

export const ClassesIndexArray = [1, 2, 4, 8, 16, 32];

export const ApartmentClasses = [
    {
        type: 'hostel',
        name: 'Hostel',
        id: 1,
	    image: "/img/slider/hostel.jpg",
	    icons: [
	    	{
        	    type: 'i-single-bed',
		        caption: 'one bed'
	        },
            {
                type: 'i-washstand',
                caption: 'washstand'
            }
	    ]
    },
    {
        type: 'economy',
        name: 'Standard',
        id: 2,
        image: "/img/slider/econom.jpg",
        icons: [
            {
                type: 'i-double-bed',
                caption: 'bed (double)'
            },
            {
                type: 'i-shower',
                caption: 'shower'
            }
        ]
    },
    {
        type: 'business',
        name: 'Business',
        id: 4,
        image: "/img/slider/business.jpg",
        icons: [
            {
                type: 'i-bath',
                caption: 'bathroom'
            },
            {
                type: 'i-parking',
                caption: 'parking space'
            },
            {
                type: 'i-office',
                caption: 'office'
            },
            {
                type: 'i-bedroom',
                caption: 'bedroom'
            }
        ]
    },
    {
        type: 'apartment',
        name: 'Lux Apartment',
        id: 8,
        image: "/img/slider/apartment.jpg",
        icons: [
            {
                type: 'i-bath',
                caption: 'jacuzzi'
            },
            {
                type: 'i-parking',
                caption: 'garage'
            },
            {
                type: 'i-office',
                caption: 'large\<br\>living room'
            },
            {
                type: 'i-bedroom',
                caption: '3 bedrooms'
            },
            {
                type: 'i-veranda',
                caption: 'balcony<br>(veranda)'
            }
        ]
    },
    {
        type: 'house',
        name: 'House / Villa',
        id: 16,
        types: ['House', 'Villa'],
        image: "/img/slider/house.jpg",
        image2: "/img/slider/house2.jpg",
        icons: [
            {
                type: 'i-bath',
                caption: 'jacuzzi'
            },
            {
                type: 'i-parking',
                caption: 'garage'
            },
            {
                type: 'i-office',
                caption: 'large\<br\>living room'
            },
            {
                type: 'i-bedroom',
                caption: '2 or more<br>bedrooms'
            },
            {
                type: 'i-garden',
                caption: 'garden'
            }
        ]
    },
	{
        type: 'private',
        name: 'Manor, Castle,<br> Private island',
        id: 32,
        types: ['Manor', 'Castle', 'Private island'],
		caption: "Castle",
		caption2: "Island",
        image: "/img/slider/castle.jpg",
        image2: "/img/slider/island.jpg",
        icons: [
            {
                type: 'i-driver',
                caption: 'personal<br>driver'
            },
            {
                type: 'i-helicopter',
                caption: 'helicopter<br>on request'
            },
            {
                type: 'i-stars',
                caption: 'any conveniences<br>on request'
            },
        ]
	}
];
