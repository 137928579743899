let scripts = {};

export function loadScript(url) {
	if (scripts[url] && scripts[url].promise) {return scripts[url].promise;}

	const p = new Promise(function(resolve, reject) {
		if (scripts[url] && scripts[url].script) {return resolve(url);}

		let script = document.createElement('script');
		script.src = url;
		script.onload = () => {
			scripts[url].script = script;
			resolve(url);
		};
		script.onerror = (e) => {
		    console.error('Error loading script:', e);
		    reject(url);
		};

		scripts[url] = {
			head: document.querySelector('head').appendChild(script),
			promise: p
		};
	});
	return p;
}

export function loadScripts(urls) {
	let result = [];
	for (let url of urls) {
		result.push(loadScript(url));
	}
	return Promise.all(result);
}
