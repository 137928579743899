import {backend, user} from './services/backend';

export class Requests {
  constructor() {
    this.message = "Requests placeholder";
  }

  activate(params, routeConfig) {
    this.params = params;

    backend.service('requests')
      .find({query: {userId: user.id}})
      .then(data => {
        console.log('Requests:', data);
        this.requests = data;
      }).catch(e => console.error(e))
  }
}


