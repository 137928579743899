import { backend, user } from './services/backend';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import moment from 'moment';
import { initPlaceApi } from './services/google';
import { DecimalPrecision } from './utils';

import { Classes } from './services/apartment-types';

//console.log('Persi:', DecimalPrecision);

@inject(Router)
export class Request {
	classes = JSON.parse(JSON.stringify(Classes));

	request = {};

	offers = null;

	constructor(router) {
		this.router = router;
		this.moment = moment;
		this.DecimalPrecision = DecimalPrecision;
		this.user = user;
		this.parent = router.container.viewModel;
		//console.log('Persi:', DecimalPrecision);
	}

	activate(params, routeConfig) {
		this.params = params;
		this.parent.dark = true;
		console.log('request module activate!!!', params);
	}

	determineActivationStrategy(params, routeConfig, navigationInstruction) {
		console.log('Requst determineActivationStrategy:', {
			params,
			routeConfig,
			navigationInstruction,
		});
		return 'replace';
		return 'invoke-lifecycle';
	} /**/

	attached() {
		if (this.params.id) {
			backend
				.service('requests')
				.get(this.params.id)
				.then((data) => {
					console.log('Request:', data);
					data.days = Math.abs(
						moment(data.checkin).diff(moment(data.checkout), 'days')
					);
					this.request = data;

					if (this.request.selectedApartmentId) {
						return this.router.navigate(
							`request/${this.request.id}/${this.request.selectedOfferId}`
						);
					}

					backend
						.service('offers')
						.on('created', (offer) => {
							if (offer.requestId != this.request.id) {
								return;
							}
							this.createMarker(offer);
							this.fitMarkers();
						})
						.find({ query: { requestId: this.request.id } })
						.then((data) => {
							console.log('Offers:', data);
							this.offers = data;
							console.log('Offers loaded:', data);
							initPlaceApi(() => {
								setTimeout(() => {
									this.initMap();
								}, 500);
								//this.initMap();
							});
						})
						.catch((e) => console.error(e));
				})
				.catch((e) => console.error(e));
		}
	}

	remove() {
		if (confirm('Are you sure?')) {
			backend
				.service('requests')
				.remove(this.params.id)
				.then((data) => {
					console.log(data);
					this.router.navigate('/');
				})
				.catch(function (e) {
					console.error(e.message);
				});
		}
	}

	fitMarkers() {
		if (!this.markers) {
			return true;
		}

		let bounds = new google.maps.LatLngBounds();
		bounds.extend(this.cityLatLon);
		for (let i = 0; i < this.markers.length; i++) {
			console.log('Position:', this.markers[i].getPosition());
			bounds.extend(this.markers[i].getPosition());
		}

		console.log('Map bounds:', bounds);
		//this.map.setMaxZoom(15);
		this.map.fitBounds(bounds);
		//setTimeout(() => {this.map.setMaxZoom(null)}, 500);
	}

	createMarker(offer) {
		let latLon = new google.maps.LatLng(offer.apartment.lat, offer.apartment.lon);

		let marker = new google.maps.Marker({
			position: latLon,
			map: this.map,
			icon: {
				url: '/img/marker.png',
			},
			title: 'Marker',
		});
		//marker.setIcon('/img/marker_map_blue.png');
		//marker.setIcon('/img/marker.png');
		offer.marker = marker;
		this.markers.push(marker);

		marker.addListener('click', function () {
			console.log('Offer click:', offer);
		});
		console.log('Marker created:', offer.marker);
	}

	initMap() {
		let img = {
			url: 'img/marker.png',
		};
		let img2 = {
			url: 'img/marker2.png',
		};
		this.cityLatLon = new google.maps.LatLng(
			this.request.data.lat || 39,
			this.request.data.lon || -0.010771
		);

		let mapOptions = {
			zoom: 10,
			center: this.cityLatLon,
			mapTypeId: google.maps.MapTypeId.HYBRID,
			// disableDefaultUI: true // отключение интерфейса
		};
		let map = new google.maps.Map(document.getElementById('map'), mapOptions);

		console.log('Map created:', map);
		this.map = map;
		this.markers = [];

		for (let i in this.offers) {
			let offer = this.offers[i];
			this.createMarker(offer);
		}

		setTimeout(this.fitMarkers, 1000);
		this.fitMarkers();
	}

	book(offer) {
		//this.request.selectedOfferId = offer.id;
		//this.request.selectedApartmentId = offer.apartmentId;
		//return this.router.navigate(`request/${this.request.id}/${offer.id}`);
		if (!offer) {
			return;
		}
/*
		if (!user.userData.mangoId) {
			return this.router.navigateToRoute('book', {requestId: this.params.id, offerId: offer.id});
		}
*/
		var platform = "platron";

        backend
            .service('payment')
            .create({
                id: offer.id,
                lang: this.parent.locale,
                url: window.location.origin + `/request/${this.request.id}/${offer.id}/`,
				platform
            })
            .then((data) => {
                console.log('Payment created:', data);
                if (data.error && data.error.errors) {this.paymentErrors = JSON.stringify(data.error.errors, 0, '\t');}
                if (data.url) {
                    window.location = data.url;
                }
            });


		//return this.router.navigateToRoute('payment', {requestId: this.params.id, offerId: offer.id});
		/*backend
			.service('payment')
			.create({ id: offer.id, lang: this.parent.locale, url: window.location.origin + `/request/${this.request.id}/${offer.id}/` })
			.then((data) => {
				console.log('Payment created:', data);
				if (data.url) {
					window.location = data.url;
				}
			});*/
	}

	offerAttached(offer, e) {
		console.log('Offer attached:', offer, e);
	}

	showMapClick() {
		let showMap = false;
	}
}
