import {valueConverter} from 'aurelia-binding';

@valueConverter('camelToSpace')
export class CamelToSpaceValueConverter {
	toView(str) {
	    if (!str)
	        return '';

		return (str + '')
		    //.replace(/((?!^)[A-Z][a-z]+|(?:[A-Z]+(?![a-z])))/g, ' $1')
			.replace(/((?!^)\s{0,1}[A-Z][a-z]+|(?:[A-Z]+(?![a-z])))/g, s => ' ' + s.trim() )
			.replace(/^./, s => s.toUpperCase() ); // uppercase the first character
	}
}
